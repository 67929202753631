import React, { useState } from "react";
import Logo from "../assets/img/sign_logo.png";

const styles = {
  container: {
    position: "relative",
    margin: "50px",
    fontSize: "14px",
    userSelect: "text",
  },
  memo: {
    position: "absolute",
    top: "-30px",
    left: "130px",
    background: "#ddd",
    padding: "6px 12px",
  },

  input: {
    border: "1px solid #fff",
    padding: "0",
    fontWeight: "normal",
    userSelect: "text",
  },
  smallInput: {
    width: "80px",
  },
  largeInput: {
    width: "300px",
  },
  mobileInput: {
    width: "120px",
    marginLeft: "5px",
  },
  emailInput: {
    width: "160px",
    marginLeft: "5px",
  },
  row: {
    display: "flex",
    alignItems: "flex-end",
  },
  contactInfo: {
    display: "flex",
  },
  label: {
    fontWeight: "bold",
  },
  infoGroup: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    marginTop: "10px",
  },
  boldInput: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  regularInput: {
    fontSize: "14px",
  },
};

function Sign() {
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  return (
    <div style={styles.container}>
      <div style={styles.memo}>↓ 직급 | 부서란에는 | 를 사용해주세요.</div>

      <div style={styles.row}>
        <input
          placeholder="이름"
          style={{
            ...styles.input,
            ...styles.smallInput,
            ...styles.boldInput,
          }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          placeholder="직급 | 부서"
          style={{
            ...styles.input,
            ...styles.largeInput,
            ...styles.regularInput,
          }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <p>
        (07238) 서울시 영등포구 은행로 37, 4층(여의도동, 기계산업진흥회 본관)
      </p>

      <div style={styles.contactInfo}>
        <p style={styles.infoGroup}>
          <strong style={styles.label}>Tel.</strong>
          <input
            type="text"
            value="070-4680-6090"
            style={{
              ...styles.input,
              ...styles.mobileInput,
              ...styles.regularInput,
              width: "140px",
            }}
            readOnly
          />
          <strong style={styles.label}>Fax.</strong>
          <input
            type="text"
            value="070-4015-3929"
            style={{
              ...styles.input,
              ...styles.mobileInput,
              ...styles.regularInput,
            }}
            readOnly
          />
        </p>
      </div>

      <div style={styles.row}>
        <p style={styles.infoGroup}>
          <strong style={styles.label}>Mobile.</strong>
          <input
            placeholder="010-0000-0000"
            style={{
              ...styles.input,
              ...styles.mobileInput,
              ...styles.regularInput,
            }}
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <strong style={styles.label}>E-mail.</strong>
          <input
            placeholder="e-mail@rbrain.co.kr"
            style={{
              ...styles.input,
              ...styles.emailInput,
              ...styles.regularInput,
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </p>
      </div>

      <img src={Logo} alt="로고" style={styles.logo} />
    </div>
  );
}

export default Sign;
